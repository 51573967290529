<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <cms-component-image comp-id="logo" class="mb" :component="block.logo" v-bind="componentProps"/>
        <b-row class="mt">
            <b-col :cols="cols" :order="block.mediaProxy.layout === 'row-right' ? 2 : 0" :class="{ 'mb': isMobileLayoutOrPreview || block.mediaProxy.layout === 'default'}">
                <cms-component-text comp-id="linksTitle" :component="block.linksTitle" v-bind="componentProps"/>
                <cms-component-navigation-links comp-id="links" :component="block.links" v-bind="componentProps"/>
            </b-col>
            <b-col :cols="cols" :class="{ 'mt': isMobileLayoutOrPreview || block.mediaProxy.layout === 'default'}">
                <cms-component-text comp-id="socialLinksTitle" :component="block.socialLinksTitle" v-bind="componentProps"/>
                <cms-component-social-links comp-id="socialLinks" :component="block.socialLinks" v-bind="componentProps"/>
            </b-col>
        </b-row>
        <hr>
        <cms-component-text comp-id="copyright" :component="block.copyright" v-bind="componentProps"/>
    </cms-block>
</template>

<script>
import CmsLink from "@/components/cms/cms-link.vue";
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentNavigationLinks from "@/components/editor/rendering/components/cms-component-navigation-links.vue";
import CmsComponentSocialLinks from "@/components/editor/rendering/components/cms-component-social-links.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-footer #hot-reload-debug
export default {
    name: `cms-block-footer`,
    components: {CmsComponentSocialLinks, CmsComponentNavigationLinks, CmsLink, CmsComponentImage, CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock,
    computed: {
        cols() {
            if (this.isMobileLayoutOrPreview) {
                return 12;
            }
            return this.block.mediaProxy.layout === `default` ? 12 : ``;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
